.valuesCard {
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.13),
    inset 0px -2px 0px 0px rgba(0, 0, 0, 0.12);
  background: #f5f5f5 !important;
  padding: 10px 20px;
  border-radius: 4px;
  width: 80%;
  height: auto;
  margin: auto;
}
.valuesHeading {
  color: #c15f00;
  font-family: cursive;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
}
.textList {
  text-align: left;
  font-size: 14px;
}
.textSpan {
  color: #c15f00;
  font-family: cursive;
  font-size: 14px;
  font-weight: 600;
}
.cardText {
  text-align: left;
  color: #5a5e6a;
  font-size: 14px;
}
@media (max-width: 768px) {
  .valuesCard {
    width: 90%;
    height: 350px;
  }
  .valuesHeading {
    font-size: 20px;
  }
  .textList {
    font-size: 12px;
    padding-left: 12px;
  }
  .textSpan {
    font-size: 12px;
  }
  .cardText {
    font-size: 12px;
  }
}
