@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins:wght@400;500;600&family=Roboto&display=swap");


.PrivacyPolicy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 40px;
}

.points{
    margin-bottom: 80px;
}

.privacytitle {
  padding: 0 20px;
  font-size: 18px;
  color: #c15f00;
  font-weight: 600;
}

.title {
  font-size: 26px;
  color: #c15f00;
  text-align: left;
  font-family: "Open Sans", Arial sans-serif;
  padding: 0 20px;
}

.privacycontent {
  font-size: 14px;
  color: #000;
  text-align: left;
  padding: 0 50px;
}
