.cardMain {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.13),
    inset 0px -2px 0px 0px rgba(0, 0, 0, 0.12);
  width: 90%;
  margin: auto;
}
.cardHeader{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.cardText{
    text-align: left;
    color:#5a5e6a;
    font-size: 14px;
}
.cardHeading{
  color: #C15F00;
  font-family: cursive;
  font-size: 24px;
  font-weight: 700;
  margin-left: 5px;
}

@media (max-width: 768px) {
  .cardHeading{
    font-size: 20px;
  }
  .cardText{
    font-size: 12px;
  }
}

 
