/* Base styles for all screen sizes */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins:wght@400;500;600&family=Roboto&display=swap");

/* General styles */
.aboutmain {
  margin-top: 50px;
  margin-bottom: 30px;
}

.main {
  margin-top: 50px;
  margin-bottom: 50px;
}

.aboutdec {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.13),
    inset 0px -2px 0px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 30px;
}

.aboutheading {
  text-align: left;
  font: bold 18px "Lato", Arial, sans-serif;
  font-family: cursive;
  color: #c15f00;
}

.aboutpara {
  color: #5a5e6a;
  margin-bottom: 12px;
  font-weight: normal;
  font-size: 13px;
  font-family: "Open Sans", Arial, sans-serif;
  text-align: justify;
}

.file {
  background: #383c4b;
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 1px;
  color: #fff;
  font-size: 18px;
  margin-right: 6px;
  margin-bottom: 14px;
}

.whoweare {
  margin-top: 50px;
}

.whoweareimg {
  height: 40vh;
  max-width: 80%;
  /* max-width: 100%; */
}

/* Media queries */

/* Mobile */
@media only screen and (max-width: 767px) {
  .aboutheading {
    font-size: 16px;
  }

  .aboutpara {
    font-size: 12px;
  }

  .file {
    font-size: 14px;
  }
  .whoweare {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Additional mobile styles */
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .aboutdec {
    padding: 15px;
  }

  /* Additional tablet styles */
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .aboutheading {
    font-size: 20px;
  }

  .aboutpara {
    font-size: 13px;
  }

  /* Additional laptop styles */
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
  .aboutmain {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  /* Additional larger screen styles */
}
