/* Base styles for all screen sizes */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins:wght@400;500;600&family=Roboto&display=swap");

/* General styles */
.Contactmain {
  margin-top: 50px;
  margin-bottom: 50px;
}

.ContactForm {
  margin: auto;
}

.input {
  width: 100%;
  margin: 10px;
  display: block;
  width: 100%;
  padding: 9px 20px;
  border: 1px solid #d7d8db;
  border-radius: 2px;
  font-size: 15px;
  font-weight: bold;
  margin-top: 20px;
  color: #5a5e6a;
}

.file {
  background: #383c4b;
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 1px;
  color: #fff;
  font-size: 18px;
  margin-right: 6px;
  margin-bottom: 14px;
}

.aboutheading {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: #c15f00;
}

.container {
  background: #fff;
  padding: 20px;
  margin: 0 10px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.13),
    inset 0px -2px 0px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  position: relative;
}

.submit {
  position: absolute;
  bottom: 20px;
  right: 12px;
  background: #c15f00;
  color: #fff;
  padding: 5px 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bolder;
}
.submit:hover {
  background-color: #fff;
  border: 1px solid #c15f00;
  color: #006a91 #c15f00;
  text-decoration: none;
}

.add {
  font: normal 13px/22px "Open Sans", Arial, sans-serif;
  text-align: left;
  margin-bottom: 0;
}

.darkadd {
  color: #c15f00;
}

.social {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* Media queries */

/* Mobile */
@media only screen and (max-width: 767px) {
  .container {
    padding: 15px;
    margin-top: 15px;
    
  }
  /* .submit {
    position: static;
    display: block;
    margin-top: 10px;
  } */
  /* Additional mobile styles */
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .aboutheading {
    font-size: 16px;
  }
  /* Additional tablet styles */
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .aboutheading {
    font-size: 20px;
    color: #c15f00;
  }
  .input {
    font-size: 16px;
    padding: 10px 20px;
  }
  .submit {
    font-size: 16px;
    padding: 8px 20px;
    bottom: 15px;
    right: 15px;
  }
  /* Additional laptop styles */
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
  .aboutheading {
    font-size: 24px;
  }
  .input {
    font-size: 18px;
    padding: 12px 20px;
  }
  .submit {
    font-size: 18px;
    padding: 10px 24px;
    bottom: 20px;
    right: 20px;
  }
  /* Additional larger screen styles */
}
