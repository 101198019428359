.techCard {
  width: 70%;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.13),
    inset 0px -2px 0px 0px rgba(0, 0, 0, 0.12);
  margin: auto auto 25px;
  overflow: hidden;
}
.techHead {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.techList {
  list-style-type: none;
}
.techListInner {
  text-align: left;
  color: #5a5e6a;
  font-size: 14px;
}
.techHeadingText {
  font-size: 18px;
  font-weight: bold;
  margin-left: 3%;
  color: #C15F00;
}

.linkser{
  color: #001427;
  font-size: 14px;
}

@media (max-width:768px){
    .techCard{
        width: 100%;
    }
}