.aboutMain{
    position: relative;
}
.aboutImage{
  width: 100%;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.aboutText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutBannerText{
    color: #000;
    font-size: 18px;
    font-weight: 900;
}
.aboutUsCardMain{
    background-color: #f5f5f5;
    padding: 35px 0
}
.subCards{
  width: 100%;
  height: 100%;
  padding: 35px 0;
}
.homeLink{
  color: #000;
  font-size: 18px;
  font-weight: 700;
}
.aboutLink{
  color: #000;
  font-size: 18px;
  font-weight: 700;
}

/* mission card*/

