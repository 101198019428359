.ContactBanner {
  position: relative;
  z-index: 2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../public/images/contact.jpg");
  padding-top: 100px;
  padding-bottom: 100px;
}

.careerBanner {
  position: relative;
  z-index: 999;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.732), rgba(0, 0, 0, 0.23)),
    url("../../../public/images/career.jpg");
  padding-top: 250px;
  padding-bottom: 100px;
}
.ContactBanner::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background: #ffffff94;
  opacity: 0.3;
  z-index: -1;
}

.contactheading {
  font: normal 30px/20px "Lato", Arial, sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #c15f00;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  z-index: 99999;
}

.backoption {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.arrowicon {
  color: #fff;
}
/* .arrowiconCareer {
  color: #c15f00;
} */

.home {
  color: #c15f00;
}
.home:hover {
  color: #cecfd2;
  text-decoration: none;
}

.contact {
  color: #c15f00;
}
.contact:hover {
  color: #fff;
  text-decoration: none;
}

/* Media queries */

/* Mobile */
@media only screen and (max-width: 767px) {
  .contactheading {
    font: normal 15px/20px "Lato", Arial, sans-serif;
  }
  .backoption {
    font-size: 12px;
  }

  /* Additional mobile styles */
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* Additional tablet styles */
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  /* Additional laptop styles */
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
  /* Additional larger screen styles */
}
