.servicesMain {
  position: relative;
}
.servicesImage {
  width: 100%;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.servicesText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeLink {
  color: #000;
  font-size: 18px;
  font-weight: 700;
}
.serviceLink {
  color: #000;
  font-size: 18px;
  font-weight: 700;
}
.sectionTitle {
  font-size: 25px;
  line-height: 50px;
  color: #f57211;
  font-weight: 700;
}
.servicesSection {
  padding: 50px 0 50px 0;
  background: #f5f5f5;
  text-align: center;
}
