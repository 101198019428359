.detailContainer {
  padding: 35px;
  background: #f5f5f5;
  margin: 20px;
}

@media(max-width:768px){
  .detailContainer {
    padding: 10px;
  }
}
