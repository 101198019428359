/* Base styles for all screen sizes */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins:wght@400;500;600&family=Roboto&display=swap");

/* General styles */
.HardWorking {
  background: #fff;
  padding: 20px;
  margin: auto;
}

.hardheading {
  text-align: left;
  font: bold 18px "Lato", Arial, sans-serif;
  font-family: cursive;
  color: #C15F00;
}

.para1 {
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.13),
    inset 0px -2px 0px 0px rgba(0, 0, 0, 0.12);
  background: #f5f5f5;
  padding: 12px;
  border-radius: 4px;
  color: #353a48;
  margin-bottom: 12px;
  font-weight: normal;
  font-size: 13px;
  font-family: "Open Sans", Arial, sans-serif;
  text-align: justify;
}

/* Media queries */

/* Mobile */
@media only screen and (max-width: 767px) {
  .hardheading {
    font-size: 16px;
  }

  .para1 {
    font-size: 12px;
    padding: 15px;
  }
  .HardWorking {
    padding: 10px;
  }

  /* Additional mobile styles */
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .HardWorking {
    padding: 15px;
  }

  /* Additional tablet styles */
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .hardheading {
    font-size: 20px;
  }

  /* Additional laptop styles */
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
  .para1 {
    font-size: 12px;
    padding: 25px;
  }

  /* Additional larger screen styles */
}
