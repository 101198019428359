/* Base styles for all screen sizes */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins:wght@400;500;600&family=Roboto&display=swap");

/* General styles */
.footermain {
  background-color: #001427;
  padding-top: 50px;
}

.footerdiv {
  margin: auto;
}

.aboutheading {
  color: #c15f00;
  text-align: left;
  padding-bottom: 11px;
  margin-bottom: 14px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #3d404d;
}

.aboutcontent {
  color: #d6d6d8;
  font-size: 12px;
  line-height: 21px;
  text-align: justify;
  margin-bottom: 20px;
}

.techul {
  list-style-type: none;
  padding-left: 0;
}

.techli {
  color: #d6d6d8;
  font-size: 11px;
  text-align: left;
  margin-bottom: 12px;
  margin: 0 0 10px;
  line-height: 20px;
  padding: 0 0 0 24px;
  position: relative;
  cursor: pointer;
}

.links {
  color: #d6d6d8;
  text-align: left;
}
.links:hover {
  color: #fff;
  text-shadow: 0 1px 0 #000;
  background-position: 2px center;
  text-decoration: none;
}

.location {
  max-width: 8%;
  margin-left: -25px;
  margin-right: 7px;
}

/* .socialcopyrights {
  margin-top: 70px;
} */

.copy {
  color: #a9abb0;
  line-height: 68px;
  font: normal 13px/22px "Open Sans", Arial, sans-serif;
  text-align: left;
}

.textUnderline {
  text-decoration: underline;
}

.socialmedia {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.facebook,
.twitter,
.google,
.youtube {
  color: #c15f00;
  font-size: 16px;
  margin-right: 10px;
  background-color: #3d404d;
  padding: 5px;
  border-radius: 5px;
}

.arrow {
  max-width: 12%;
  margin-left: -25px;
  margin-right: 8px;
}

/* Media queries */

/* Mobile */
@media only screen and (max-width: 767px) {
  .socialmedia {
    justify-content: start;

    margin: auto;
  }
  .aboutheading {
    text-align: center;
    margin-bottom: 10px;
  }
  .location,
  .arrow {
    max-width: 8%;
    margin-left: -25px;
    margin-right: 8px;
  }
  .aboutheading {
    font-size: 14px;
  }
  .aboutcontent {
    font-size: 11px;
  }
  .techli {
    font-size: 11px;
    margin-bottom: 10px;
  }
  .copy {
    font-size: 12px;
    margin: 0;
  }
  .facebook,
  .twitter,
  .google,
  .youtube {
    font-size: 14px;
    padding: 3px;
  }
  .socialcopyrights {
    margin-top: 30px;
  }
  .privacy {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }
  .footermain {
    padding: 10px;
  }
  /* Additional mobile styles */
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .aboutheading {
    font-size: 16px;
  }
  .aboutcontent {
    font-size: 12px;
  }
  .techli {
    font-size: 12px;
  }
  .copy {
    font-size: 13px;
  }
  /* Additional tablet styles */
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .aboutheading {
    font-size: 18px;
  }
  .aboutcontent {
    font-size: 13px;
  }

  .arrow {
    max-width: 12%;
    margin-left: -25px;
    margin-right: 8px;
  }
  .privacy {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }
  .copy {
    margin: 0;
  }
  /* Additional laptop styles */
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
  .aboutheading {
    font-size: 20px;
  }

  .arrow {
    max-width: 12%;
    margin-left: -25px;
    margin-right: 8px;
  }
  /* .socialcopyrights {
    margin-top: 70px;
  } */
  .privacy {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    margin: 0;
  }
  .copy {
    margin: 0;
  }
  /* Additional larger screen styles */
}
