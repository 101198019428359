.servicesMain {
  position: relative;
}
.servicesImage {
  width: 100%;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.servicesText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #cccccc26;
  opacity: 1;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.iconcontent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.arrowicon {
  color: #fff;
}
.home {
  color: #c15f00;
}
.home:hover {
  color: #fff;
  text-decoration: none;
}
.subPage {
  color: #c15f00;
  text-transform: capitalize;
}
.subPage:hover {
  color: #fff;
  text-decoration: none;
}
.bannerHeading {
  font: normal 30px/20px "Lato", Arial, sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #c15f00;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
}

/* Media queries */

/* Mobile */
@media only screen and (max-width: 767px) {
  .bannerHeading {
    font: normal 15px/20px "Lato", Arial, sans-serif;
  }

  .arrowicon {
    font-size: 8px;
  }
  .iconcontent{
    font-size: 12px;
  }

  /* Additional mobile styles */
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* Additional tablet styles */
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  /* Additional laptop styles */
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
  /* Additional larger screen styles */
}
