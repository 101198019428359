.sectionImage {
  width: 100%;
  height: 400px;
}
.detailsCard {
  width: 100%;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.13), inset 0px -2px 0px 0px rg;
}
.detailsHeading {
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
  color: #f57211;
  text-align: left;
}
.detailsDescription {
  color: #5a5e6a;
  font-size: 14px;
  font: normal 13px/22px "Open Sans", Arial, sans-serif;
  text-align: left;
}
.contentOuter {
  padding: 20px;
}
@media (max-width: 768px) {
  .detailsCard {
    width: 100%;
  }
  .sectionImage {
    width: 100%;
    height: auto;
  }
}
