@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins:wght@400;500;600&family=Roboto&display=swap");

.careerTitle {
  font-size: 26px;
  color: #c15f00;
  font-family: "Open Sans", Arial sans-serif;
  padding: 0 20px;
  font-weight: bold;
}

.container {
  width: 80vw;
  margin: 50px auto 30px;
}
.container p {
  text-align: left;
}
.card {
  display: flex;
  flex-direction: row;
  width: 80vw;
  margin: 20px auto 100px;
}
.graduate {
  width: 20vw;
  height: 250px;
  /* background-color: #c7792a; */
  position: relative;
  text-align: left;
  padding: 10px;
  color: black;
  margin-right: 20px;
  border-radius: 9px;
}

.cardLink:hover{
    text-decoration: none;
}
.cardIcon {
  width: 45%;
  position: absolute;
  top: 70%;
  right: 10px;
  transform: translateY(-50%);
  opacity: 0.5;
}

@media only screen and (max-width: 767px) {
  .card {
    flex-direction: column;
    /* width: 100vw; */
    text-align: center;
    margin: 20px auto 100px;
  }
  .graduate {
    width: 80vw;
    height: 260px;
    padding: 20px;
    margin-bottom: 20px;
  }
  .cardIcon {
    width: 35%;
    transform: translateY(-70%);
  }
}
