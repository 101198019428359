body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.carouselContainer {
  position: relative;
  margin: auto;
}
.carouselImg {
  width: 100%;
  height: 70vh;
}

/* body {
  width: 100vw;
  height: 100vh;
  background: #151515;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.carouselContainer {
  position: relative;
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.carouselImage {
  width: 100%;
  max-height: 100vh; /* Use max-height to maintain aspect ratio */
  /* Maintain aspect ratio and fit within the container */
}

.imageText {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  z-index: 1;
  white-space: nowrap;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
}

.imageText::after {
  content: "";
  display: block;
  width: 30px;
  height: 2px;
  background-color: #f57211;
  position: absolute;
  bottom: -4px; /* Adjust this value to control the distance between text and the line */
  left: 50%;
  transform: translateX(-50%);
}
.imageText {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  z-index: 1;
  white-space: nowrap;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
}
.dots {
  position: absolute;
  bottom: 20%; /* Adjust the distance from the bottom */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 6px; /* Adjust the spacing between dots */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #c15f00; /* Change the active dot color */
}

.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  /* background: white; */
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
  transform: scale(-1);
}

.carouselImage {
  position: absolute;
  width: 100%;
  /* height: 70vh; */
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  /* background: white; */
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 60px;
  z-index: 2;
  color: #c15f00;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
  transform: scale(-1);
}

/* CSS */

video {
  /** Simulationg background-size: cover */
  object-fit: cover;
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
}

.video-wrapper {
  /* Telling our absolute positioned video to 
  be relative to this element */
  position: relative;

  width: 100%;
  height: 60vh;

  /* Will not allow the video to overflow the 
  container */
  overflow: hidden;

  /* Centering the container's content vertically 
  and horizontally */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}



@media only screen and (max-width: 767px) {
  .imageText {
    font-size: 16px;
  }
  .next,
  .prev {
    width: 20px;
    height: 20px;
    font-size: 24px;
    top: calc(50% - 85px);
  }
  .carouselContainer {
    height: 45vh;
  }
  .dots{
    bottom: 50%;
  }
  .video-wrapper{
    height: 40vh;
  }

  /* Additional mobile styles */
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* Additional tablet styles */
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  /* Additional laptop styles */
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
  /* Additional larger screen styles */
}
