.maps {
  font: normal 30px/20px "Lato", Arial, sans-serif !important;
  text-transform: capitalize;
  margin-bottom: 40px !important;
  color: #c15f00;
  /* text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5); */
}

.Mapsbottom {
  margin-bottom: 40px;
}

/* Media queries */

/* Mobile */
@media only screen and (max-width: 767px) {
  .maps {
    font: normal 15px/20px "Lato", Arial, sans-serif !important;
  }
  .maps1 {
    width: 100%;
    padding: 30px;
    /* height: 300px; */
  }
  .maps2 {
    width: 100%;
    padding: 30px;
    /* height: 300px; */
  }

  /* Additional mobile styles */
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* Additional tablet styles */
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  /* Additional laptop styles */
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
  .maps1 {
    width: 100%;
    padding: 30px;
    /* height: 300px; */
  }
  .maps2 {
    width: 100%;
    padding: 30px;
    /* height: 300px; */
  }
  /* Additional larger screen styles */
}
