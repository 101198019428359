.missionCard {
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.13),
    inset 0px -2px 0px 0px rgba(0, 0, 0, 0.12);
  background: #f5f5f5 !important;
  padding: 20px;
  border-radius: 4px;
  width: 80%;
  height: 250px;
  margin: auto;
}
.missionHeading {
  color: #c15f00;
  font-family: cursive;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
}
.textList {
  text-align: left;
  font-size: 14px;
}

.cardText {
  text-align: left;
  color: #5a5e6a;
  font-size: 14px;
}
.cardHeading {
  color: #c15f00;
  font-family: cursive;
  font-size: 24px;
  font-weight: 700;
  margin-left: 5px;
}

@media (max-width: 768px) {
  .missionCard {
    width: 90%;
    height: 350px;
  }
  .missionHeading {
    font-size: 20px;
  }
  .textList {
    font-size: 12px;
    padding-left: 12px;
  }
  .cardHeading {
    font-size: 20px;
  }
  .cardText {
    font-size: 12px;
  }
}
