@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins:wght@400;500;600&family=Roboto&display=swap");

.navbar {
  background-color: #fafafa;
  font-family: "Open Sans", Arial sans-serif;
  box-shadow: 0px 0px 12px 0px #ccc;
}

.navlink {
  font-size: 14px;
  font-weight: bold;
  line-height: 70px;
  padding: 0 20px;
  font-family: "Open Sans", Arial sans-serif;
  color: #001427;
  display: block;
  position: relative;
}

.navlink:hover {
  text-decoration: none;
}

/* .navbarnav {
  overflow: hidden;
  background-color: #f1f1f1;
} */
/* .navbarnav .navlink {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 3px solid transparent;
  
} */

.navbarnav .navlink:hover {
  color: #c15f00;
  border-bottom: 3px solid #c15f00;
}

.navbarnav .navlink.active {
  color: #c15f00;
  border-bottom: 3px solid #c15f00;
}

.dropdownitem {
  background: transparent;
}
.dropdownitem:hover {
  color: #001427;
  background: #c15f00;
}
.dropdownitem:active {
  background: #c15f00;
}

/* Mobile Styles */
@media (max-width: 767px) {
  .enablelogo {
    width: 13rem;
  }
  .navbarnav .navlink:hover {
    color: #c15f00;
    border-bottom: 3px solid #c15f00;
    display: inline-block;
  }
  /* CSS rules for mobile devices */
}

/* Tablet Styles */
@media (min-width: 768px) and (max-width: 991px) {
  /* CSS rules for tablet devices */
  .enablelogo {
    width: 20rem;
  }
}

/* Laptop Styles */
@media (min-width: 992px) and (max-width: 1199px) {
  /* CSS rules for laptops */
  .enablelogo {
    width: 20rem;
  }
}

/* Larger Screen Styles */
@media (min-width: 1200px) {
  .enablelogo {
    width: 20rem;
  }
  /* CSS rules for larger screens (desktops) */
}
