/* Base styles for all screen sizes */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Montserrat&family=Nunito&family=Open+Sans&family=Poppins:wght@400;500;600&family=Roboto&display=swap");

/* General styles */
.slickarrow {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.slickprev {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.slicknext {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.slidercarsouel {
  margin-top: 30px;
}

.slider {
  padding: 50px 0 50px 0;
}

.imgtestautomation {
  border-radius: 10px 10px 0 0;
}

.card {
  position: relative;
  background: #fff;
  padding-bottom: 23px;
  margin: 4px 0 3px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.13),
    inset 0px -2px 0px 0px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}

.cardbody {
  padding: 10px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
  color: #001427;
}

.title:hover {
  text-decoration: none;
  color: #c15f00;
}

.desc {
  margin-bottom: 18px;
  color: #5a5e6a;
  padding: 0 10px;
  font: normal 13px/22px "Open Sans", Arial, sans-serif;
  text-align: center;
}

.overallheading {
  font-size: 25px;
  line-height: 50px;
  color: #c15f00;
  font-family: "Lato", Arial, sans-serif;
  text-align: center;
  font-weight: 700;
}

.readmore {
  font: bold 13px "Open Sans", Arial, sans-serif;
  display: inline-block;
  height: 29px;
  line-height: 29px;
  color: #fff;
  padding: 0 15px;
  border-radius: 4px;
  margin-right: 17px;
  background-color: #c15f00;
}

.readmore:hover {
  text-decoration: none;
  color: #fff;
  background-color: #006a91b8;
}

.imgtestautomation {
  /* max-width: 100%; */
  width: 264px;
  height: 200px;
}

/* Media queries */

/* Mobile */
@media only screen and (max-width: 767px) {
  .slickprev,
  .slicknext {
    display: none;
  }
  .card {
    border-radius: 0;
  }
  .desc {
    padding: 0;
  }
  .overallheading {
    font-size: 16px;
  }
  /* Additional mobile styles */
  .imgtestautomation {
    /* max-width: 100%; */
    width: 100%;
    height: 200px;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .slickprev,
  .slicknext {
    display: block;
  }
  /* Additional tablet styles */
  .imgtestautomation {
    /* max-width: 100%; */
    width: 100%;
    height: 200px;
  }
}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  /* Additional laptop styles */
  .imgtestautomation {
    /* max-width: 100%; */
    width: 264px;
    height: 200px;
  }
}

/* Larger screens */
@media only screen and (min-width: 1366px) {
  .slider {
    padding: 70px 0 70px 0;
  }
  .readmore {
    font-size: 15px;
  }
  /* Additional larger screen styles */
  .imgtestautomation {
    /* max-width: 100%; */
    width: 264px;
    height: 200px;
  }
}
